import { REFRESH_PERMISSIONS, RESET, SET_AUTH_INFO, SET_PERMISSIONS } from "../actions/auth.action";
const initialState = {
    info: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        token: null,
        isLoggedIn:false
    }
   };



     const AuthReducer = (state = initialState, action) => {
       switch (action.type) {
         case SET_AUTH_INFO:
           return { ...state, info: action.data };
         case SET_PERMISSIONS:
           return { ...state, permissions: action.data };
           case REFRESH_PERMISSIONS:
      return { ...state, permissions: action.data };
         case RESET:
           return initialState;
         default:
           return state;
       }
     };
     
     export default AuthReducer;
     