// path: hooks/usePermission.js
import { useEffect, useState } from "react";
import secureLocalStorage from "./secureLocalStorage";
import { ActionKeywords } from "./permissions";

const usePermission = (component) => {
    const [permissions, setPermissions] = useState({
        create: false,
        update: false,
        delete: false,
        anyPermission: false,
    });

    useEffect(() => {
        // Fetch permissions from local storage
        const currentPermissions = secureLocalStorage.getItem("permissions") || [];
        const userPermissions = Array.isArray(currentPermissions) ? currentPermissions : [];

        const permissions = userPermissions.flatMap(permission => permission.permissions);

        // Calculate permissions for the given component
        const updatedPermissions = Object.fromEntries(
            Object.entries(ActionKeywords).map(([action, keyword]) => [
                action,
                permissions.includes(`${keyword} ${component}`),
            ])
        );
        // Add `anyPermission` flag
        updatedPermissions.anyPermission = Object.values(updatedPermissions).some(Boolean);

        // Update state with calculated permissions
        setPermissions(updatedPermissions);
    }, [component]); // Recalculate when `component` changes

    return permissions;
};

export default usePermission;
