import React, { useRef, useCallback } from 'react';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { batch, useDispatch } from 'react-redux';
import { folderActions, suiteActions, testPlanActions } from './store/actions';
import secureLocalStorage from './utils/secureLocalStorage';
import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';

const UserMenu = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menu = useRef(null);
  const user = React.useMemo(() => secureLocalStorage.getItem('currentUser'), []);

  const logout = useCallback(() => {
    secureLocalStorage.clear();
    batch(() => {
      dispatch({ type: 'RESET' });
      dispatch({ type: folderActions.RESET });
      dispatch({ type: suiteActions.RESET_SUITE });
      dispatch({ type: testPlanActions.RESET });
    });

    setTimeout(() => {
      navigate('/login');
    }, 100);
  }, [dispatch, navigate]);

  const items = React.useMemo(
    () => [
      {
        template: (item, options) => {
          return (
            <button onClick={e => options.onClick(e)} className={classNames(options.className, 'w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround')}>
              <Avatar image={user?.avatar || '/male.png'} className='mr-2' shape='circle' />
              <div className='flex flex-column align'>
                <span className='font-bold'>{user?.name}</span>
                <span className='text-sm'>{user?.role}</span>
              </div>
            </button>
          );
        },
      },
      {
        label: 'Profile',
        command: () =>   navigate('/user/profile'),
        disabled: true,
      },
      {
        label: 'Logout',
        command: logout,
      },
    ],
    [logout],
  );

  return (
    <button onClick={event => menu.current.toggle(event)} className={'md:w-full w-3rem justify-content-center p-link flex align-items-center justify-content*center py-2 md:px-3 px-1 text-color hover:surface-200 md:border-round-lg border-circle card mb-0'}>
      <Menu model={items} popup ref={menu} className='mt-2' />
      <Avatar image={user?.avatar || '/male.png'} className='md:mr-2' shape='circle' />
      <div className='md:flex hidden flex-column align'>
        <span className='font-bold'>{user?.name}</span>
        <span className='text-sm'>{user?.role}</span>
      </div>
    </button>
  );
});

export default UserMenu;
