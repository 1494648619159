import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react'

const UserProfile = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center">
        <ProgressSpinner />
        <span className="text-center">In progress...</span>
      </div>
    );
  };
  
  export default UserProfile;
