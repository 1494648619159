import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { useMutation } from 'react-query';
import { testPlanService } from '../../../services';
import { testPlanActions } from '../../../store/actions';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import PermissionButtonGroup from '../../../components/ActionsButtons';
import { Components } from '../../../utils/permissions';
const UpdatePlanItem = ({ item, index, getTestPlans, testPlanId }) => {
  const [updatedPlanItem, setUpdatedPlanItem] = useState(item?.name ?? null);
  const popupRef = React.useRef(null);
  const deletePopupRef = React.useRef(null);
  const toast = React.useRef(null);
  const [visible, setVisible] = useState(false);
  const [delPopUp, setDelPopUp] = useState(false);
  const selectedItemIndex = useSelector(state => state.testPlan.activeItemIndex);
  const dispatch = useDispatch();
  const refectData = async () => {
    getTestPlans && getTestPlans();
  };

  const showSuccessToast = message => {
    toast.current.show({ severity: 'success', summary: 'Info', detail: message });
  };

  const color = selectedItemIndex === index ? 'text-white' : '';

  const showErrorToast = error => {
    toast.current.show({
      severity: 'error',
      summary: 'Alert',
      detail: error.response?.data?.message || error.message,
    });
    console.error(error, 'Error occurred');
  };

  const { mutate: UpdatePlanItem, isLoading } = useMutation({
    mutationFn: async e => {
      if (!updatedPlanItem) {
        throw new Error('Type the plan item name to update');
      }
      const res = await testPlanService.updatePlanItemName(item?.id, { name: updatedPlanItem, test_plan_id: testPlanId });
      return res;
    },
    onSuccess: async res => {
     
      if (res?.success) {
        const { message } = res;
        showSuccessToast(message);
        setVisible(false);
        await refectData();
      }
    },
    onError: error => {
      showErrorToast(error);
    },
  });

  const { mutate: deletePLanItem, isLoading: deleteLoading } = useMutation({
    mutationFn: async e => {
      const res = await testPlanService.deletePlanItemName(item?.id);
      return res;
    },
    onSuccess: async res => {
     
      if (res?.success) {
        const { message } = res;
        setDelPopUp(false);
        showSuccessToast(message);
        await refectData();
        dispatch({ type: testPlanActions.CLEAR_ALL });
      }
    },
    onError: error => {
      showErrorToast(error);
    },
  });

  const updatePanItemTemplate = () => {
    return (
      <>
        <InputText placeholder='Enter updated name' className='w-full' value={updatedPlanItem ?? ''} onChange={e => setUpdatedPlanItem(e.target.value ?? 'null')} />
        <span className='p-buttonset flex justify-content-end mt-1'>
          <Button
            icon='pi pi-check'
            size='small'
            className='action-button'
            loading={isLoading}
            onClick={e => {
              e.stopPropagation();
              UpdatePlanItem();
            }}
          />
          <Button
            icon='pi pi-times'
            size='small'
            severity='danger'
            className='action-button'
            onClick={e => {
              e.stopPropagation();
              setVisible(false);
            }}
          />
        </span>
      </>
    );
  };

  const deletePlanItemTemplate = () => {
    return (
      <>
        <div className='flex gap-2 align-items-center'>
          <i className='pi pi-info-circle'></i>
          <p>Do you want to delete this record?</p>
        </div>
        <span className='p-buttonset flex justify-content-end mt-1'>
          <Button
            icon='pi pi-check'
            size='small'
            className='action-button'
            loading={deleteLoading}
            onClick={e => {
              e.stopPropagation();
              deletePLanItem();
            }}
          />
          <Button
            icon='pi pi-times'
            size='small'
            severity='danger'
            className='action-button'
            onClick={e => {
              e.stopPropagation();
              setDelPopUp(false);
            }}
          />
        </span>
      </>
    );
  };

  return (
    <div className='p-buttonset flex'>
      <Toast ref={toast} />
      <ConfirmPopup pt={{ message: { className: 'ml-0' }, footer: { className: 'hidden' } }} target={popupRef.current} visible={visible} message={updatePanItemTemplate} onHide={() => setVisible(false)} acceptClassName='hidden' rejectClassName='hidden' />

      <ConfirmPopup pt={{ message: { className: 'ml-0' }, footer: { className: 'hidden' } }} target={deletePopupRef.current} visible={delPopUp} message={deletePlanItemTemplate} onHide={() => setDelPopUp(false)} acceptClassName='hidden' rejectClassName='hidden' />

      <PermissionButtonGroup
        component={Components.TEST_PLAN}
       
        actions={[
          {
            action: 'update',
            className: `action-button ${color}`,
            tooltip: 'Edit Test Plan Item',
            iconSize: 14,
            ref :popupRef,
            onClick: e => {
              e.stopPropagation();
              setVisible(s => !s);
            },
          },
          ...(!item?.added_suites_count > 0
            ? [
                {
                  action: 'delete',
                  className: `action-button ${color}`,
                  iconSize: 14,
                  ref :deletePopupRef,
                  tooltip:'Delete Test Plan Item',
                  onClick: e => {
                    e.stopPropagation();
                    setDelPopUp(true);
                  },
                },
              ]
            : []),
        ]}
      />
      {/* <Button
        ref={popupRef}
        icon='pi pi-pencil'
        tooltip='Edit Test Plan Item'
        text
        severity='info'
        className={color + ' action-button'}
        onClick={e => {
          e.stopPropagation();
          setVisible(true);
        }}
      />

      {!item?.added_suites_count > 0 && (
        <Button
          ref={deletePopupRef}
          icon='pi pi-trash'
          tooltip='Delete Test Plan Item'
          severity='danger'
          text
          className={color + ' action-button'}
          onClick={e => {
            e.stopPropagation();
            setDelPopUp(true);
          }}
        />
      )} */}
    </div>
  );
};

export default UpdatePlanItem;
