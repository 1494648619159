
import { userService } from '../../services';
import secureLocalStorage from '../../utils/secureLocalStorage';

// Action types
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const RESET = 'RESET';
export const REFRESH_PERMISSIONS = 'REFRESH_PERMISSIONS';
// Action creators
export const setAuthInfo = (data) => ({
  type: SET_AUTH_INFO,
  data,
});

export const setUserPermissions = () => async (dispatch) => {
  try {
    const response = await userService.getPermissions();
    const permissions = response?.data ?? [];
    secureLocalStorage.setItem('permissions', permissions);
    dispatch({
      type: SET_PERMISSIONS,
      data: permissions,
    });
  } catch (error) {
    console.error('Failed to fetch permissions:', error);
    dispatch({
      type: SET_PERMISSIONS,
      data: [], // Fallback to empty permissions
    });
  }
};

export const resetState = () => ({
  type: RESET,
});


export const refreshPermissions = () => async (dispatch) => {
    try {
      const response = await userService.getPermissions();
      const permissions = response?.data || [];
  
      // Update local storage
      secureLocalStorage.setItem('permissions', permissions);
  
      // Dispatch to update Redux state
      dispatch({
        type: REFRESH_PERMISSIONS,
        data: permissions,
      });
    } catch (error) {
      console.error('Failed to refresh permissions:', error);
    }
  };