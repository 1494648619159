import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import LoaderButton from '../../components/common/loaderButton/LoaderButton';
// import _debounce from 'lodash/debounce';

import { Formik, ErrorMessage } from 'formik';
import { InputText } from 'primereact/inputtext';
import { testPlanService, userService } from '../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useNavigate } from 'react-router-dom';
import { random } from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import * as Yup from 'yup';

import PermissionButtonGroup from '../../components/ActionsButtons';
import { Components } from '../../utils/permissions';
import usePermission from '../../utils/usePermissionsHook';
export default function TestRunnerList() {
  const [testPlanDialog, setTestPlanDialog] = useState(false);
  const [areaPathSuggestions, setAreaPathSuggestions] = useState([]);
  const [sugesstionLoading, setSuggestionLoading] = useState(false);
  const [reloadList, setReloadList] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [t, setT] = useState(null);
  const {anyPermission} = usePermission(Components.TEST_PLAN);
  const allSelectedPath = useRef([]);
  const areaPathParent = useRef(null);
  const fields = {
    name: '',
    area_path: '',
    type: '',
    users_id: [],
  };

  const hideProjectDialog = useCallback(() => {
    setTestPlanDialog(false);
  }, []);

  const getUsers = useCallback(() => {
    const controller = new AbortController();
    userService
      .getUsers(controller)
      .then(({ data }) => {
        setUsersList(data);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => getUsers(), [getUsers]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    area_path: Yup.string().required('Area path is required'),
    type: Yup.string().required('Type is required'),
    // users_id: Yup.string().required('Area path is required'),
  });

  const Err = ({ name }) => {
    return (
      <div style={{ color: '#ff4032' }}>
        <ErrorMessage name={name} />
      </div>
    );
  };
  const onChangeAreaPath = (e, setFieldValue) => {
    setFieldValue('area_path', e.target.value);

    const splittedPaths = e.target.value.split('/');
    if (splittedPaths[splittedPaths.length - 1] === '') {
      splittedPaths.pop();
    }

    if (splittedPaths.length === 0) {
      areaPathParent.current = null;
      allSelectedPath.current = [];
      setAreaPathSuggestions([]);
    } else {
      allSelectedPath.current.forEach(p => {
        if (p.name === splittedPaths[splittedPaths.length - 1]) {
          areaPathParent.current = p.id;
        }
      });
    }

    const controller = new AbortController();
    console.log(areaPathParent.current);
    let query = { query: e.target.value };

    if (areaPathParent.current) {
      query = { parent_id: areaPathParent.current };
    }

    setSuggestionLoading(true);
    if (t) clearTimeout(t);
    setT(
      setTimeout(() => {
        testPlanService
          .getAreaPathSuggestions(query, controller)
          .then(res => {
            setAreaPathSuggestions(res.data);
           
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => setSuggestionLoading(false));
      }, 750),
    );
  };

  const onSelectAreaPath = (data, prev, setFieldValue) => {
    console.log(data, prev);
    areaPathParent.current = data.id;

    allSelectedPath.current.push(data);
    setFieldValue('area_path', prev.includes('/') ? prev + data.name : data.name);
    setAreaPathSuggestions([]);
  };

  const addTestPlanSubmit = (values, { setSubmitting }) => {
    testPlanService
      .createTestPlan(values)
      .then(res => {
        setTestPlanDialog(false);
        setReloadList(random(1, 2000));
        allSelectedPath.current = [];
        areaPathParent.current = null;

        // console.log(res)
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  
  return (
    <div>
     {anyPermission && <PermissionButtonGroup
        wrapperClass={'p-1 bg-blue-900 border-round text-white mb-1'}
        component={Components.TEST_PLAN}
        actions={[
          {
            action: 'create',
            className: 'text-white',
            onClick: () => {
              setTestPlanDialog(true);
            },
          },
        ]}
      />}

      <List reload={reloadList} />

      <Dialog visible={testPlanDialog} maximizable style={{ width: '650px' }} header='Test Plan' modal className='p-fluid' onHide={hideProjectDialog}>
        <div>
          <Formik initialValues={fields} onSubmit={addTestPlanSubmit} enableReinitialize validationSchema={validationSchema}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
              <div>
                {/* <h5>Advanced</h5> */}
                <form onSubmit={handleSubmit}>
                  <div className='p-fluid formgrid grid'>
                    <div className='field col-12 md:col-12'>
                      <label htmlFor='name'>Name</label>
                      <InputText id='name' name='name' type='text' onChange={handleChange} value={values.name} />
                      <Err name='name' />
                    </div>
                    <div className='field col-12 md:col-12'>
                      <label htmlFor='name'>Area Path</label>
                      <span className='p-input-icon-right'>
                        {sugesstionLoading && <i className='loader' style={{ borderTopColor: '#6c757d' }} />}
                        <InputText
                          autoComplete='off'
                          id='area_path'
                          name='area_path'
                          value={values.area_path}
                          type='text'
                          onChange={e => {
                            onChangeAreaPath(e, setFieldValue);
                          }}
                        />
                      </span>
                      {areaPathSuggestions.length > 0 && (
                        <div className='ml-2 p-listbox p-component '>
                          <div className='p-listbox-list-wrapper '>
                            <ul className='p-listbox-list'>
                              {areaPathSuggestions.map(data => {
                                return (
                                  <li className={`p-listbox-item`} onClick={() => onSelectAreaPath(data, values.area_path, setFieldValue)}>
                                    {data.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                      <Err name='area_path' />
                    </div>

                    <div className='field col-12 md:col-12'>
                      <label htmlFor='users'>Type</label>
                      <select className='p-inputtext p-component' name='type' onChange={handleChange}>
                        <option value=''>Select Type</option>
                        <option value='1' selected={values.type === '1'}>
                          Manual
                        </option>
                        <option value='2' selected={values.type === '2'}>
                          Automated
                        </option>
                      </select>
                      <Err name='type' />
                    </div>

                    <div className='field col-12 md:col-12'>
                      <label htmlFor='users'>Users</label>
                      <MultiSelect
                        placeholder='Assign Users'
                        value={values.users_id}
                        options={usersList}
                        optionValue='id'
                        optionLabel='name'
                        maxSelectedLabels={3}
                        onChange={e => {
                          setFieldValue('users_id', e.value);
                        }}
                      />
                    </div>
                  </div>
                  <pre>{/* {JSON.stringify(values, null, ' ')} */}</pre>
                  <div style={{ textAlign: 'right' }}>
                    {/*<Button label="Cancel" type='button' icon="pi pi-times" className="p-button-text w-auto" />*/}
                    <LoaderButton keepText type='submit' icon={!isSubmitting && 'pi pi-check'} className='p-button-text w-auto' loading={isSubmitting} spinnerStyle={{ borderTop: '3px solid #6366f1' }}>
                      Save
                    </LoaderButton>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

function List({ reload }) {
  const navigate = useNavigate();

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [dataRows, setDataRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTestPlans = useCallback(() => {
    const { rows: limit, page } = lazyParams;
    setLoading(true);
    const controller = new AbortController();
    testPlanService
      .getTestPlans(controller, { limit, page: page + 1 })
      .then(({ data }) => {
        setDataRows(data.data);
        setTotalRecords(data.meta.total);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, [lazyParams]);

  useEffect(() => getTestPlans(), [getTestPlans, reload]);

  const onPage = event => {
    setLazyParams(event);
  };

  const nameTemplate = row => {
    return (
      <div className='cursor-pointer' onClick={() => navigate(`${row.id}`)}>
        {row.name}
      </div>
    );
  };

  const statusTemplate = row => {
    return <StatusSwitch value={row.status} id={row.id} />;
  };

  // const onRowClick=({data,index,orignalEvent})=>{
  //       navigate(`${data.id}`)
  // }

  return (
    <>
      <DataTable
        paginator
        first={lazyParams.first}
        rows={10}
        totalRecords={totalRecords}
        onPage={onPage}
        lazy
        value={dataRows}
        dataKey='id'
        loading={loading}
        className='datatable-responsive'
        emptyMessage='No Data.'
        // onRowClick={onRowClick}
        responsiveLayout='scroll'
      >
        <Column field='name' header='Name' body={nameTemplate}></Column>
        <Column field='last_updated' header='Last Updated'></Column>
        <Column field='status' header='status' body={statusTemplate}></Column>
      </DataTable>
    </>
  );
}

function StatusSwitch({ value, id }) {
  const toast = useRef();
  const [checked, setChecked] = useState(parseInt(value) === 1);
  const [loading, setLoading] = useState(false);
  const onChange = () => {
    if (loading) return;
    setLoading(true);
    testPlanService
      .updateStatus(id, !checked)
      .then(res => {
        setChecked(!checked);
        toast.current.show({ severity: 'success', summary: 'Updated ', detail: 'Status has been updated.' });
       
      })
      .catch(err => {
        console.log(err);
        toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Something went wrong' });
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Toast ref={toast} />
      <InputSwitch checked={checked} onChange={onChange} />
      {loading && <span className='loader' style={{ borderTop: '3px solid #9e9e9e', marginBottom: '5px' }}></span>}
    </>
  );
}
