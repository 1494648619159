import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserPermissions } from '../store/actions/auth.action';
import secureLocalStorage from './secureLocalStorage';

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Track loading state
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true); // Ensure loading state is true during fetch

      // Fetch permissions from local storage
      let currentPermissions = secureLocalStorage.getItem("permissions") || [];
      if (!currentPermissions?.length) {
        // Dispatch action to fetch permissions if not present locally
        await dispatch(setUserPermissions());
        currentPermissions = secureLocalStorage.getItem("permissions") || [];
      }

      // Flatten permissions and set state
      const flattenedPermissions = currentPermissions.flatMap((perm) => perm.permissions || []);
      setPermissions(flattenedPermissions);
      setLoading(false); // Stop loading when permissions are ready
    };

    fetchPermissions();
  }, [dispatch]);

  const value = useMemo(() => ({ permissions, loading }), [permissions, loading]);

  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};

export default PermissionContext;
