// import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import React, { useState, useEffect, useRef, Suspense, useCallback } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';
import ProjectList from './Modules/Project/ProjectList';

import { Ripple } from 'primereact/ripple';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import { Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';
import secureLocalStorage from './utils/secureLocalStorage';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { ProgressSpinner } from 'primereact/progressspinner';
import Suite from './Modules/Folders/Testsuite/Suite';
import Spinner from './components/common/Spinner/Spinner';
import TestPlanList from './Modules/TestPlan/TestPlanList';
import TestPlanDetails from './Modules/TestPlan/TestPlanDetails/TestPlanDetails';
import TestPlanLogs from './Modules/TestPlan/Logs/TestPlanLogs';
import CustomVariables from './Modules/CustomVariables/CustomVariable';
import Defects from './Modules/Defects';
import ConfigVariables from './Modules/ConfigVariables';
import TestConfiguration from './Modules/TestConfiguration';
import { ComponentIcon, ConfigurationIcon, DashboardIcon, DefectsIcon, LogsIcon, ProjectsIcon, RolesIcon, TestConfigurationIcon, TestIcon, TestPlanIcon, TicketingIcon, UserIcon, VariablesIcon } from './utils/icons';
import AfterBeforeStringBuilder from './Modules/AfterBeforeStringBuilder';
import { PERMISSIONS } from './utils/permissions';
import { PermissionProvider } from './utils/usePermissions';
import ProtectedRoute from './utils/ProtectedRoute';
import UnauthorizedPage from './Unauthorized';
import { refreshPermissions } from './store/actions/auth.action';
import UserProfile from './Modules/Users/UserProfile';

const Login = React.lazy(() => import('./components/Login/Login'));
const ResetPassword = React.lazy(() => import('./components/Login/ResetPassword'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const RoleAdd = React.lazy(() => import('./Modules/Roles/RoleAdd'));
const Folders = React.lazy(() => import('./Modules/Folders/Folders'));
const UsersList = React.lazy(() => import('./Modules/Users/UsersList'));
const ComponentAdd = React.lazy(() => import('./Modules/Components/ComponentAdd'));
const ComponentsList = React.lazy(() => import('./Modules/Components/ComponentsList'));
const TicketList = React.lazy(() => import('./Modules/Ticketing/TicketList'));
const App = () => {
  const { isLoggedIn } = useSelector(state => state.auth.info);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const copyTooltipRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const currentUser = secureLocalStorage.getItem('currentUser');

    if (currentUser.token) {
      if (location.pathname === '/login') {
        navigate('/');
      }
      dispatch({ type: 'SET_AUTH_INFO', data: { ...currentUser, isLoggedIn: true } });
    } else {
      // Exclude redirection for /reset-password
      if (location.pathname !== '/reset-password') {
        navigate('login');
      }
    }
  }, [navigate, location.pathname, dispatch]);



  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('light');
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  useEffect(() => {
    dispatch(refreshPermissions());
    // return () => clearInterval(intervalId);
  }, [dispatch]);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });
  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = inputStyle => {
    setInputStyle(inputStyle);
  };

  const onRipple = e => {
    console.log('onRipple', e);
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = mode => {
    setLayoutMode(mode);
  };

  const onColorModeChange = mode => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = event => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = event => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive(prevState => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive(prevState => !prevState);
      }
    } else {
      setMobileMenuActive(prevState => !prevState);
    }
    if (event) event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = event => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive(prevState => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = event => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = event => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const allowedMenu = useCallback(menu => {
    const currentUser = secureLocalStorage.getItem('currentUser');
    if (!currentUser?.user_permissions) return menu;

    // Flatten permissions array from user_permissions
    const permissions = currentUser.user_permissions.reduce((acc, permission) => [...acc, ...permission.permissions], []);

    // If 'Has Client' permission exists, return the full menu
    if (permissions.includes('Has Client')) return menu;

    // Filter menu based on permissions without direct mutation
    const filteredMenu = menu.map(section => ({
      ...section,
      items: section.items.filter(navItem => filterNavItem(navItem, permissions)),
    }));

    return filteredMenu;
  }, []);

  const filterNavItem = (navItem, permissions) => {
    return navItem.permissions.some(itemPermission => permissions.includes(itemPermission));
  };
  const allMenus = [
    {
      label: 'Home',
      items: [
        {
          label: 'Dashboard',
          icon: <DashboardIcon size={21} />,
          to: '/',
          permissions: PERMISSIONS.DASHBOARD,
        },
        {
          label: 'Projects',
          icon: <ProjectsIcon size={21} />,
          to: '/projects',
          permissions: PERMISSIONS.PROJECT,
        },
        {
          label: 'Test',
          icon: <TestIcon size={21} />,
          to: '/folders',
          permissions: PERMISSIONS.TEST,
        },
        {
          label: 'Test Plans',
          icon: <TestPlanIcon size={21} />,
          to: '/test-plan',
          permissions: PERMISSIONS.TEST_PLAN,
        },
        {
          label: 'Test Plans Logs',
          icon: <LogsIcon size={21} />,
          to: '/test-plan-logs',
          permissions: PERMISSIONS.TEST_PLAN_LOGS,
        },
        {
          label: 'Roles',
          icon: <RolesIcon size={21} />,
          to: '/roles',
          permissions: PERMISSIONS.ROLE,
        },
        {
          label: 'Users',
          icon: <UserIcon size={21} />,
          to: '/users',
          permissions: PERMISSIONS.USER,
        },
        {
          label: 'Components',
          icon: <ComponentIcon size={21} />,
          to: '/components',
          permissions: PERMISSIONS.COMPONENT,
        },
        {
          label: 'Custom Variables',
          icon: <VariablesIcon size={21} />,
          to: '/custom-variables',
          permissions: PERMISSIONS.CUSTOM_VARIABLE,
        },
        {
          label: 'Ticketing',
          icon: <TicketingIcon size={24} />,
          to: '/ticketing',
          permissions: PERMISSIONS.TICKET,
        },
        {
          label: 'Defects',
          icon: <DefectsIcon size={21} />,
          to: '/defects',
          permissions: PERMISSIONS.DEFECT,
        },
        {
          label: 'Config Variables',
          icon: <ConfigurationIcon size={21} />,
          to: '/config-variables',
          permissions: PERMISSIONS.CONFIG_VARIABLE,
        },
        {
          label: 'Test Configuration',
          icon: <TestConfigurationIcon size={21} />,
          to: '/test-configuration',
          permissions: PERMISSIONS.TEST_CONFIGURATION,
        },
      ],
    },
  ];

  const menu = allowedMenu(allMenus);

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  };

  const wrapperClass = classNames('layout-wrapper flex', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className={wrapperClass} onClick={onWrapperClick} style={{ height: '100dvh' }}>
        {isLoggedIn ? (
          <>
            <Tooltip ref={copyTooltipRef} target='.block-action-copy , .tootlip' position='bottom' content='Copied to clipboard' event='focus' />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className='layout-sidebar' onClick={onSidebarClick}>
              <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
              {/* <Sidebar /> */}
            </div>

            <div className='layout-main-container flex-1 overflow-hidden'>
              <div className='layout-main card overflow-y-auto  w-full'>
                <Suspense fallback={<Spinner />}>
                  <PermissionProvider>
                    <Routes>
                      <Route path='/' exact={true} element={<ProtectedRoute element={<Dashboard />} requiredPermissions={['Read Dashboard']} />} />
                      <Route path='/projects' element={<ProtectedRoute element={<ProjectList />} requiredPermissions={['Read Project']} />} />
                      <Route path='/folders' element={<ProtectedRoute element={<Folders />} requiredPermissions={["Read Test"]} />} />
                      <Route path='/suite' element={<ProtectedRoute element={<Suite onToggleMenuClick={onToggleMenuClick} />} requiredPermissions={["Read Test"]} />} />
                      <Route path='/suite/:id' element={<ProtectedRoute element={<Suite onToggleMenuClick={onToggleMenuClick} />} requiredPermissions={["Read Test" ,"Update Test"]} />} />
                      <Route path='test-plan' element={<ProtectedRoute element={<TestPlanList />} requiredPermissions={["Read Test Plan"]} />} />
                      <Route path='test-plan/:id' element={<ProtectedRoute element={<TestPlanDetails />} requiredPermissions={["Read Test Plan", "Update Test Plan"]} />} />
                      <Route path='test-plan-logs' element={<ProtectedRoute element={<TestPlanLogs />} requiredPermissions={["Read Test Plan Logs"]} />} />

                      <Route path='/roles' element={<ProtectedRoute element={<RoleAdd />} requiredPermissions={["Read Role"]} />} />
                      <Route path='/users'  element={<ProtectedRoute element={<UsersList />} requiredPermissions={["Read User"]} />} />
                      <Route path='/user/profile'  element={<UserProfile />} />
                      <Route path='/components' element={<ProtectedRoute element={<ComponentsList />} requiredPermissions={["Read Component"]} />} />
                      <Route path='/components/add' element={<ProtectedRoute element={<ComponentAdd />} requiredPermissions={["Read Component" ,"Create Component"]} />} />
                      <Route path='/components/:id/edit' element={<ProtectedRoute element={<ComponentAdd />} requiredPermissions={["Read Component" ,"Update Component"]} />} />
                      <Route path='/custom-variables' element={<ProtectedRoute element={<CustomVariables />} requiredPermissions={["Read Custom Variable"]} />} />
                      <Route path='/ticketing' element={<ProtectedRoute element={<TicketList />} requiredPermissions={["Read Ticket"]} />} />
                      <Route path='/defects' element={<ProtectedRoute element={<Defects />} requiredPermissions={["Read Defect"]} />} />
                      <Route path='/config-variables'   element={<ProtectedRoute element={<ConfigVariables />} requiredPermissions={["Read Config Variable"]} />} />
                      <Route path='/test-configuration' element={<ProtectedRoute element={<TestConfiguration />} requiredPermissions={["Read Test Configuration"]} /> }  />
                      <Route path='/string-builder' element={<AfterBeforeStringBuilder />}  />
                      <Route path='/unauthorized' element={<UnauthorizedPage />} />
                    </Routes>
                  </PermissionProvider>
                </Suspense>
              </div>

              <AppFooter layoutColorMode={layoutColorMode} />
            </div>
          </>
        ) : (
          <Suspense
            fallback={
              <div className='flex justify-content-center align-items-center h-full w-full'>
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth='6' fill='var(--surface-ground)' animationDuration='.5s' />
              </div>
            }
          >
            <Routes>
              <Route path='/login' exact={true} element={<Login />} />
              <Route path='/reset-password' element={<ResetPassword />} />
            </Routes>
          </Suspense>
        )}
        <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

        <CSSTransition classNames='layout-mask' timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
          <div className='layout-mask p-component-overlay'></div>
        </CSSTransition>
      </div>
    </QueryClientProvider>
  );
};

export default App;
