import api from '../utils/api';

class UserService {
  /**
   *
   * @param {object} data Object of user data
   * @returns
   */
  createUser(data) {
    const config = { url: '/users', method: 'post', data };
    return api.request(config);
  }

  /**
   *
   * @param {string} id id of user
   * @param {AbortController} abortController to abort request on unmount
   * @returns
   */
  getUser(id, abortController) {
    const config = { url: `/users/${id}`, method: 'get', ...(abortController && { signal: abortController.signal }) };
    return api.request(config);
  }

  /**
   *
   * @param {AbortController} abortController to abort request on unmount
   * @returns
   */
  getUsers(abortController, params = null) {
    const config = {
      url: '/users',
      method: 'get',
      ...(abortController && { signal: abortController.signal }),
      ...(params && { params: params }),
    };
    return api.request(config);
  }
  /**
   *
   * @param {string} id id of user
   * @returns
   */
  deleteUser(id) {
    const config = { url: `/users/${id}`, method: 'delete' };
    return api.request(config);
  }
  /**
   *
   * @param {Array} ids array of ids
   * @param {AbortController} abortController
   * @returns
   */

  deleteBulk(ids, abortController) {
    const config = {
      url: `/users/bulk-delete`,
      method: 'post',
      params: {user_ids: ids },
      ...(abortController && { signal: abortController.signal }),
    };
    return api.request(config);
  }

  /**
   *
   * @param {string} id id of user to update
   * @param {Object} data Object of user data
   * @returns
   */
  updateUser(id, data) {
    const config = { url: `/users/${id}`, method: 'put', data };
    return api.request(config);
  }

  /**
   *
   * @param {object} data for forgot password
   * @param {AbortController}
   * @returns
   */

  forgotPassword(data) {
    const config = { url: `/forgot-password`, method: 'POST', data };
    return api.request(config);
  }

  /**
   *
   * @param {object} data for reset password
   * @param {AbortController}
   * @returns
   */

  resetPassword(data) {
    const config = { url: `/reset-password`, method: 'POST', data };
    return api.request(config);
  }

  getPermissions(abortController) {
    const config = { url: `/user/permissions`, method: 'get', ...(abortController && { signal: abortController.signal }) };
    return api.request(config);
  }
}

const userService = new UserService();
export default userService;
